import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/services';
import { Helper } from '../helper/helper';
import { Select } from '@ngxs/store';
import { UserState } from '../state/user.state';
import { User } from '../../core/models/user';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    @Select(UserState) $currentUser: Observable<User>;

    constructor(private router: Router, private authService: AuthService, private helper: Helper) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const user = this.authService.userValue;
        if (user) {
            const requiredRoles: [] = route.data.roles;

            if (!requiredRoles || requiredRoles.length === 0) {
                return true;
            } else {
                let granted = false;
                for (const requiredRole of requiredRoles) {
                    if (this.helper.hasRole(requiredRole)) {
                        granted = true;
                        break;
                    }
                }
                if (granted) {
                    return true;
                } else {
                    this.router.navigate(['login']);
                    // return false;
                }
            }
        }
        this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
        });
        return false;
    }
}
