import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RegistrationService } from '../core/services/registration.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SwiperContainer } from 'swiper/element';
import SwiperOptions from 'swiper';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewInit {
    products: any[] = [];
    env = environment;
    langSwitch = 'de';
    langSwitchUri = '';
    selectionBoxHeight = '--selections-height: 100px;';

    index = 0;

    Breakpoints = Breakpoints;
    currentBreakpoint: string = '';

    readonly breakpoint$ = this.breakpointObserver.observe(['(min-width: 1025px)']).pipe(distinctUntilChanged());

    @ViewChild('selectionSwiper') selectionSwiper!: ElementRef<SwiperContainer>;
    @ViewChild('backgroundSwiper') backgroundSwiper!: ElementRef<SwiperContainer>;
    @ViewChild('selectionSupplier') selectionSupplier!: ElementRef<HTMLElement>;

    swiperBackgroundConfig: SwiperOptions = {
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            // @ts-ignore
            delay: 4000,
        },
        speed: 4000,
    };

    swiperSelectionConfig: SwiperOptions = {
        // @ts-ignore
        slidesPerView: 3,
        spaceBetween: 15,
        loop: true,
        loopAdditionalSlides: 2,

        breakpoints: {
            2200: {
                slidesPerView: 5,
            },
            1800: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    };

    constructor(private registrationService: RegistrationService, private breakpointObserver: BreakpointObserver) {
        const uri = window.location.pathname.split('/');
        this.langSwitchUri = uri.join('/');
        switch (uri[1]) {
            case 'de':
                uri[1] = 'en';
                this.langSwitchUri = uri.join('/');
                this.langSwitch = 'en';
                break;
            case 'en':
                uri[1] = 'de';
                this.langSwitchUri = uri.join('/');
                this.langSwitch = 'de';
                break;
            default:
                this.langSwitch = 'de';
        }
    }

    // *************************************************************************
    //
    //  Swiper Buttons
    //
    // *************************************************************************
    btnPrevSwiper(): void {
        this.selectionSwiper.nativeElement.swiper.slidePrev();
    }

    btnNextSwiper(): void {
        this.selectionSwiper.nativeElement.swiper.slideNext();
        const btnPrev = document.querySelector('.carousel .button-prev');
        if (btnPrev.classList.contains('show') === false) {
            btnPrev.classList.add('show');
        }
    }

    // *************************************************************************
    //
    //  ngAfterViewInit
    //
    // *************************************************************************
    ngAfterViewInit() {
        this.backgroundSwiper.nativeElement.initialize();
        this.breakpoint$.subscribe({
            next: () => {
                this.breakpointChanged();
                // console.log(this.currentBreakpoint);
                if (this.currentBreakpoint === 'Desktop') {
                    this.selectionSwiper.nativeElement.initialize();
                    this.selectionSwiper.nativeElement.addEventListener('swiperresize', () => {
                        this.selectionBoxHeight = '--selections-height: ' + this.selectionSupplier.nativeElement.offsetHeight + 'px;';
                    });
                }
            },
        });
    }

    // *************************************************************************
    //
    //  ngOnInit
    //
    // *************************************************************************
    ngOnInit(): void {
        this.registrationService.getRandomPublicProducts(2).subscribe({
            next: (data) => {
                this.products = data;
            },
        });

        // Breakpoints
        // ****************************************
        this.breakpoint$.subscribe(() => this.breakpointChanged());
    }

    // *************************************************************************
    //
    //  breakpointChanged => Responsive Layout
    //
    // *************************************************************************
    private breakpointChanged() {
        if (this.breakpointObserver.isMatched('(min-width: 1025px)')) {
            this.currentBreakpoint = 'Desktop';
        } else if (this.breakpointObserver.isMatched('(max-width: 1024px)')) {
            this.currentBreakpoint = 'Tablet';
        } else if (this.breakpointObserver.isMatched('(max-width: 480px)')) {
            this.currentBreakpoint = 'Phone';
        }
    }
}
