import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetRegisterData } from './register.actions';

@State<any>({
    name: 'registerData',
    defaults: {
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        roles: [],
        company: '',
        position: '',
        street: '',
        country_id: '',
        additionalAddress: '',
        userType: null,
        password: '',
        password_repeat: '',
        zip: '',
        city: '',
        selectedCountry: [],
        url: '',
        language: '',
    },
})
@Injectable()
export class RegisterState {
    constructor() {}

    @Action(SetRegisterData)
    public addValue(ctx: StateContext<any>, { payload }: any): void {
        ctx.patchState(payload);
    }
}
