import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {SwiperContainer} from 'swiper/element';
import SwiperOptions from 'swiper';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit, AfterViewInit {
    items: MenuItem[];
    @ViewChild('backgroundSwiper') backgroundSwiper!: ElementRef<SwiperContainer>;
    
    swiperBackgroundSlider: SwiperOptions = {
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            // @ts-ignore
            delay: 4000,
        },
        speed: 4000,
    };

    constructor(public messageService: MessageService) {
    }

    ngOnInit(): void {
        this.items = [
            {
                label: 'Personal',
                routerLink: 'step1',
            },
            {
                label: 'Type',
                routerLink: 'step2',
            },
            {
                label: 'Company',
                routerLink: 'step3',
            },
            {
                label: 'Questions',
                routerLink: 'step4',
            },
            {
                label: 'Summary',
                routerLink: 'step5',
            },
            {
                label: 'Success',
                routerLink: 'step6',
            },
        ];
    }

    ngAfterViewInit(): void {
        this.backgroundSwiper.nativeElement.initialize();
    }
}
