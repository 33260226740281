import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingIndicatorService {
    // tslint:disable-next-line:variable-name
    private _loading = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading.asObservable();
    public _loader = false;

    constructor() {}

    show(): void {
        this._loader = true;
        this._loading.next(true);
    }

    hide(): void {
        this._loader = false;
        this._loading.next(false);
    }
}
