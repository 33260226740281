import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { MustMatch } from '../../shared/helper/mustmatch-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../core/services/registration.service';
import { SwiperContainer } from 'swiper/element';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
    form: UntypedFormGroup;
    password: string;
    submitted = false;
    token: string;
    @ViewChild('backgroundSwiper') backgroundSwiper!: ElementRef<SwiperContainer>;


    constructor(
        private formBuilder: UntypedFormBuilder,
        private message: MessageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private registrationService: RegistrationService
    ) {
        this.activatedRoute.params.subscribe((params) => {
            const activeId = this.activatedRoute.snapshot.params.token;
            if (activeId) {
                this.token = activeId;
            } else {
                this.token = params['id'];
            }
        });
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
            password_repeat: ['', Validators.required]
        }, { validator: MustMatch('password', 'password_repeat') });
    }

    showMessage(msg: string, type = 'error', summary = 'Fehler'): void {
        this.message.add({
            key: 'msgs',
            severity: type,
            life: 5000,
            summary,
            detail: msg
        });
    }


    submit(): void {
        this.submitted = true;
        if (this.form.invalid) {
            console.log(this.form);
            this.showMessage(
                $localize`Eingaben fehlerhaft!`,
                'error',
                $localize`:@@error:Fehler!`
            );
            return;
        }


        this.registrationService.passwordReset(this.form.value.password, this.token).subscribe(
            {
                next: (d) => {
                    this.showMessage(
                        $localize`:@@new.password:Neues Passwort vergeben.`,
                        'success',
                        $localize`:@@successful:Erfolgreich!`
                    );
                    this.form.reset();
                },
                error: (e) => {

                    this.showMessage(
                        $localize`:@@error:Fehler!`,
                        'error',
                        $localize`:@@error:Fehler!`
                    );
                    this.form.reset();
                }
            });
    }

    get f(): any {
        return this.form.controls;
    }

    swiperBackgroundSlider: any = {
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 4000,
        },
        speed: 4000
    }

    ngAfterViewInit(): void {
        this.backgroundSwiper.nativeElement.initialize();
    }

}
