import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Select, Store} from '@ngxs/store';
import {UserState} from '../state/user.state';
import {Observable} from 'rxjs';
import {User} from '../../core/models/user';
import {DomSanitizer} from '@angular/platform-browser';
import {DictionaryService} from '../../core/services/dictionary.service';
import {Dictionary} from '../../core/models/dictionary';

@Injectable()
export class Helper {
    @Select(UserState) $currentUser: Observable<User>;

    constructor(
        private store: Store,
        private sanitizer: DomSanitizer,
        private dictionaryService: DictionaryService) {
    }

    helperAlert(): void {
        alert('Helper Funktion');
    }

    youtubeParser(url: string): string | boolean | number {
        if(url){
            const regExp =
              /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
            const match = url.match(regExp);
            return match && match[1].length === 11 ? match[1] : false;
        }
        return false;
    }

    hasRole(role: string | number | Array<any>): any {
        // tslint:disable-next-line:no-shadowed-variable
        const state = this.store.selectSnapshot<any>((state: UserState) => state);
        const roles = state.currentUser.current_roles;
        return _.has(roles, role);
    }

    isMasterAccount(): any {
        const state = this.store.selectSnapshot<any>((state: UserState) => state);
        return state.currentUser.master_account;
    }

    isReadOnly(): any {
        const state = this.store.selectSnapshot<any>((state: UserState) => state);
        return state.currentUser.read_only;
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getValue(object: Observable<any>) {
        let value: any;
        object.subscribe((v) => (value = v));
        return value;
    }

    getCountryName(id: string): Promise<any> {
        return this.dictionaryService.getItem(id);
    }

    /**
     * Truncate a string if it's longer than the given maximum string length.
     *
     * @param str
     * @param num
     */
    truncateString(str: string, num: number): string {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str.length <= num) {
            return str;
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + '...';
    }

    millisToMinutesAndSeconds(millis: number) {
        const minutes = Math.floor(millis / 60000);
        const seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds;
    }
}

export function parseDate(rawDate: any): string {
    return new Date(rawDate).toLocaleDateString('en-US');
}

export function parseDateMonth(raw: any) {
    return new Date(raw).toLocaleDateString('en-US', {month: 'short'});
}

export function parseDateDay(raw: any) {
    return new Date(raw).toLocaleDateString('en-US', {day: 'numeric'});
}

export function capitalizeFirstLetter(input: string): string {
    if (input.length === 0) {
        return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}
