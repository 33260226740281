<router-outlet #o='outlet'></router-outlet>
<p-toast baseZIndex='9999999' key='msgs' position='bottom-center'></p-toast>
<p-confirmPopup key='popup'></p-confirmPopup>
<p-confirmDialog
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '400px' }"
    key='dialog'
></p-confirmDialog>

<p-confirmDialog #yesnodialog [transitionOptions]="'400ms cubic-bezier(0.25, 0.8, 0.25, 1)'" key='yesnodialog' styleClass="yesnodialog">

    <ng-template pTemplate='header'>
        <div class="icon"><i class='fa-light fa-question'></i></div>
    </ng-template>

    <ng-template pTemplate='footer'>
        <div class='buttons'>
            <button
                (click)='yesnodialog.reject()'
                class="btn-default"
                i18n-label='@@cancel'
                icon='pi pi-times'
                label='Abbrechen'
                pButton
                pRipple
                type='button'
            ></button>
            <button
                (click)='yesnodialog.accept()'
                class="btn-primary"
                i18n-label='@@confirm'
                icon='pi pi-check'
                label='Bestätigen'
                pButton
                pRipple
                type='button'
            ></button>
        </div>
    </ng-template>

</p-confirmDialog>


<p-dialog [(visible)]='agb' [class]="'datenschutz-message'" [closable]='false' [draggable]='false' [modal]='true' [resizable]='false' [style]="{ maxWidth: '460px' }" header='Datenschutz & Allgemeine Nutzungsbedinungen' i18n-header="@@agb.header">
    <form (ngSubmit)='confirmAgb()'>
        <p i18n='@@agb.confirm.text'>
            <strong>Möchtest du Loop Plus weiter nutzen?</strong><br/><br/>Dann bestätige bitte unsere
            <a [href]='link1' target="_blank">Datenschutzerklärung</a>
            und unsere
            <a [href]='link2' target="_blank">allgemeinen Nutzungsbedingungen</a>.
        </p>

        <div class='p-field-checkbox'>
            <p-checkbox
                [(ngModel)]='checked'
                [binary]='true'
                [required]='true'
                id='confirm-agb'
                name='confirm-agb'
            ></p-checkbox>
            <label for='confirm-agb' i18n='@@confirm.agb.text'>Hiermit akzeptiere ich die Datenschutzerklärung und die allgemeinen Nutzungsbedingungen</label>
        </div>

        <button
            class="btn-primary"
            i18n-label='@@confirm_button'
            label='Bestätigen'
            pButton
            pRipple
            type='submit'
        ></button>
    </form>

</p-dialog>
