import {Component} from '@angular/core';

@Component({
    selector: 'app-meta-nav',
    templateUrl: './meta-nav.component.html',
    styleUrl: './meta-nav.component.scss'
})
export class MetaNavComponent {
    imprintUrl = $localize`:@@navigation.imprint.url:https://www.lobster-event.com/de/impressum`;
    imprintLabel = $localize`:@@navigation.imprint:Impressum`;
    privacyUrl = $localize`:@@navigation.privacy.policy.url:https://www.lobster-event.com/de/datenschutzerklaerung`;
    privacyLabel = $localize`:@@navigation.privacy.policy:Datenschutzerklärung`;
    termsUrl = $localize`:@@navigation.terms.of.use.url:https://www.lobster-event.com/de/allgemeine-nutzungsbedingungen`;
    termsLabel = $localize`:@@navigation.terms.of.use:Nutzungsbedingungen`;
    cookieLabel = $localize`:@@navigation.cookie.policy:Cookie-Einstellungen`;
}
