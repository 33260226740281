<div id='home-login-registration' class='password-reset'>
  <div class='inner-wrapper'>
    <div class='headline' i18n='@@button.password.reset.span'>
      Passwort<br> <span>zurücksetzen</span>
    </div>

    <div class='form'>
      <div class='inner'>

        <div class='title' i18n='@@set.new.password'>Neues Passwort vergeben</div>

        <form [formGroup]='form'>

          <div class='input'>
            <p-password [inputStyleClass]=" submitted && f.password.errors ? 'input-invalid' : ''"
                        [toggleMask]='true'
                        formControlName='password'
                        i18n-placeholder='@@input.password'
                        placeholder='Passwort eingeben'
            ></p-password>
          </div>

          <div class='input'>
            <p-password [inputStyleClass]="submitted && f.password_repeat.errors ? 'input-invalid' : ''"
                        [toggleMask]='true'
                        formControlName='password_repeat'
                        i18n-placeholder='@@password.repeat'
                        placeholder='Passwort wiederholen'
            ></p-password>
          </div>

          <button
            i18n-label='@@button.password.reset'
            label='Passwort zurücksetzen'
            pButton
            pRipple
            type='submit'
            (click)='submit()'
          ></button>

        </form>
      </div>
    </div>

    <div class='copy'>
      <a [routerLink]='["/login"]' i18n-label='@@back.login'>Zurück zum Login</a>
    </div>


    <div id='background-images'>

      <swiper-container appSwiper #backgroundSwiper [config]='swiperBackgroundSlider' init='false'>
        <swiper-slide>
          <div class='image'><img src='./assets/home/background-1.jpg' /></div>
        </swiper-slide>
        <swiper-slide>
          <div class='image'><img src='./assets/home/background-2.jpg' /></div>
        </swiper-slide>
        <swiper-slide>
          <div class='image'><img src='./assets/home/background-3.jpg' /></div>
        </swiper-slide>
      </swiper-container>

    </div>
  </div>
</div>


<!--



<h1 i18n='@@button.password.reset'>Passwort zurücksetzen!</h1>
<p i18n='@@set.new.password'>Neues Passwort vergeben</p>


-->
