import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from '../../shared/state/user.actions';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private router: Router, private http: HttpClient, private store: Store, private message: MessageService) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.user = this.userSubject.asObservable();
    }

    public userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public userData;

    public get userValue(): User {
        return this.userSubject.value;
    }

    logout(): Observable<any> {
        console.log('Logout');
        return this.http.get(`${environment.appApi}/user/logout`);
    }

    /*
login(username: string, password: string): Observable<any> {
    const body = new FormData();
    body.append('username', username);
    body.append('password', password);
    body.append('grant_type', 'password');
    body.append('client_id', environment.client_id);
    body.append('client_secret', environment.client_secret);
    return this.http.post(`${environment.apiUrl}oauth/token`, body).pipe(
        map((user) => {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.userSubject.next(user as User);
            // Details abrufen
            this.getUser().then((data) => {
                this.store.dispatch(new SetCurrentUser(data));
                // Rollen in Session
                sessionStorage.setItem('currentRoles', JSON.stringify(data.current_roles));
            });
            return user;
        })
    );
}
*/

    async login(username: string, password: string): Promise<any> {
        const body = new FormData();
        body.append('username', username);
        body.append('password', password);
        body.append('grant_type', 'password');
        body.append('client_id', environment.client_id);
        body.append('client_secret', environment.client_secret);
        const request$ = this.http.post(`${environment.appApi}/oauth/token`, body).pipe(take(1));
        return await lastValueFrom(request$);

        /**
     .subscribe({
     next: (res) => {
     sessionStorage.setItem('currentUser', JSON.stringify(res));
     this.userSubject.next(res as User);
     this.getUserProfile().subscribe((r) => {
     this.store.dispatch(new SetCurrentUser(r));
     sessionStorage.setItem('currentUserData', JSON.stringify(r));
     this.router.navigate(['/dashboard']);
     });
     },
     error: (e) => {
     //console.log(e);
     this.showError(
     $localize`:@@error.login.failed:Login fehlgeschlagen!`
     );
     },
     });
     **/
    }

    // User profile
    getUserProfile(): Observable<any> {
        const api = `${environment.appApi}/user`;
        return this.http.get(api).pipe(
            map((res: Response) => {
                return res || {};
            })
        );
    }

    public getUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            firstValueFrom(this.http.get(`${environment.appApi}/user`)).then(
                (data: User) => {
                    resolve(data);
                },
                (err) => reject(err)
            );
        });
    }

    showError(msg: string): void {
        this.message.add({
            key: 'msgs',
            severity: 'error',
            summary: $localize`:@@error:Fehler!`,
            detail: msg,
        });
    }
}
