<div class='registration' id='home-login-registration'>

    <router-outlet></router-outlet>

    <div id='steps'>
        <p-steps [model]='items' [readonly]='true'></p-steps>
    </div>

    <div id='background-images'>
        <swiper-container #backgroundSwiper [config]='swiperBackgroundSlider' appSwiper init='false'>
            <swiper-slide>
                <div class='image'><img alt="Background 1" src='./assets/home/background-1.jpg'/></div>
            </swiper-slide>
            <swiper-slide>
                <div class='image'><img alt="Background 1" src='./assets/home/background-2.jpg'/></div>
            </swiper-slide>
            <swiper-slide>
                <div class='image'><img alt="Background 1" src='./assets/home/background-3.jpg'/></div>
            </swiper-slide>
        </swiper-container>
    </div>

</div>
