import { User } from '../../core/models/user';

export class SetCurrentUser {
  static readonly type = '[User API] Set current';

  constructor(public payload: User) {}
}

export class LogoutUser {
  static readonly type = '[User API] Remove currrent';
}
