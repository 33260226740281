import { Injectable, NgZone } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { LogoutUser, SetCurrentUser } from './user.actions';
import { User } from '../../core/models/user';
import { AuthService } from '../../core/services';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@State<User>({
   name: 'currentUser',
   defaults: null,
})
@Injectable()
export class UserState {
   constructor(
      private authService: AuthService,
      private router: Router,
      private ngZone: NgZone
   ) {}

   @Action(SetCurrentUser)
   setCurrent(
      { getState, setState }: StateContext<User>,
      { payload }: SetCurrentUser
   ): void {
      // const state = getState();
      setState(payload);
   }

   @Action(LogoutUser)
   logoutUser({ setState, getState }: StateContext<User>): void {
      this.authService
         .logout()
         .pipe(first())
         .subscribe(() => {
            sessionStorage.clear();
            setState(null);
            this.ngZone.run(() => this.router.navigate(['login'])).then();
         });
   }
}
