export const environment = {
    production: true,
    youtube_key: 'AIzaSyBqHbdg0OZzp7ryaUAuTnbeFQ4_4OoVcOs',
    youtube_api: 'https://www.googleapis.com/youtube/v3/videos',
    name: 'prod',
    apiUrl: 'https://www.lobster-experience.com/',
    messageServiceUrl: 'https://message-service.lobster-experience.com/',
    client_id: '9d6c8c20-71b8-405b-b855-ed72eb23cb65',
    client_secret: 'jeuYpJu9pY9DADB2vUPDEKK1nv4zq0osCUQqncWW',
    event_domain: 'https://www.lobster-event.com',
    media_url: 'https://www.lobster-experience.com',
    cdn_url:'https://cdn.lobster-experience.com/lobster/',
    websocket_url: 'wss://socket.lobster-experience.com',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG9ic3RlcmV4cGVyaWVuY2UiLCJhIjoiY2o1aTJmYWI5MXJsdTJ3czZwY3h0cXJ4YyJ9.-8m5MliR1SPfQDvUe5WVsw',
    },
    version: '1.0.55',
    appApi: 'https://app-service.lobster-experience.com/api/v1',
    buildDate: 'Sun Nov 10 2024 12:31:19 GMT+0000 (Coordinated Universal Time)',
};
