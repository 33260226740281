import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
    constructor(@Inject(LOCALE_ID) public locale: string) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'Content-Language': this.locale,
            },
        });

        return next.handle(request);
    }
}
