<div class='home' id='home-login-registration'>

    <div id='home-page' style="{{ selectionBoxHeight }}">

        <header>
            <div id='navbar-top'>
                <div class='inner'>
                    <nav>
                        <ul>
                            <li><a [routerLink]="['/login']" i18n='@@login'>Login</a></li>
                            <li><a [routerLink]="['/register/step1']" i18n='@@register'>Registrierung</a></li>
                            <li><a class="lang" href="{{ langSwitchUri }}"><i class="fa-regular fa-arrows-rotate" style="margin-right: 6px;"></i> {{ langSwitch | uppercase }}</a></li>
                            <li class="has-sub-menu">
                                <i class="fa-light fa-circle-info"></i>
                                <div class="sub-menu">
                                    <app-meta-nav></app-meta-nav>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div id='header'>

                <div class="logo">
                    <img alt="Loop Plus" src="./assets/logos/loop-plus-text-white.svg"/>
                </div>

                <div class='message-wrapper'>
                    <div class='message' i18n='@@frontpage.welcome'>Werde Teil des schnellst<br/>
                        wachsenden Netzwerks in der <br/>
                        <span>Luxusreise</span> Industrie
                    </div>
                    <div class="buttons">
                        <a [routerLink]="['/register/step1']" class='btn register' i18n='@@home.btn-register'>Jetzt Mitglied werden</a>
                        <a [routerLink]="['/login']" class='btn login' i18n='@@home.btn-login'>Login</a>
                    </div>
                </div>

                <!-- NICHT LÖSCHEN!!!! -->
                <div></div>

            </div>
        </header>


        <div id='selections'>

            <div #selectionSupplier class='selection suppliers'>
                <div class='headline'>
                    A Selection of <span>Suppliers</span>
                </div>

                <ng-container [ngSwitch]="currentBreakpoint">

                    <div *ngSwitchCase="'Desktop'" class="carousel">

                        <swiper-container #selectionSwiper [config]='swiperSelectionConfig' appSwiper class='carousel-selections' init='false'>

                            <swiper-slide *ngFor='let product of products'>
                                <a [routerLink]="['/login']" class='selection-item'>
                                    <figure>
                                        <img alt="" src='{{ env.appApi }}/resize/800/600/c?source={{ product.image }}'/>
                                    </figure>
                                    <span class="details">
                                <span class="name">{{ product.title }}</span>
                                <span class="location">{{ product.city }} | {{ product.country }}</span>
                            </span>
                                </a>
                            </swiper-slide>

                        </swiper-container>

                        <div (click)="btnPrevSwiper()" class="button-prev"></div>
                        <div (click)="btnNextSwiper()" class="button-next"></div>

                    </div><!-- /.carousel -->


                    <div *ngSwitchCase="'Tablet'" class="items">

                        <a *ngFor='let product of products' [routerLink]="['/login']" class='selection-item'>
                            <figure>
                                <img alt="" src='{{ env.media_url }}/resize/800/600/c/{{ product.image }}'/>
                            </figure>
                            <span class="details">
                                <span class="name">{{ product.title }}</span>
                                <span class="location">{{ product.city }} | {{ product.country }}</span>
                            </span>
                        </a>

                    </div>

                </ng-container>

            </div> <!-- /.selection -->

        </div><!--/#selections-->


        <ng-container [ngSwitch]="currentBreakpoint">
            <footer *ngSwitchCase="'Tablet'">
                <div class="inner">
                    <app-meta-nav></app-meta-nav>
                </div>
            </footer>
        </ng-container>

    </div><!--/#home-page -->


    <div id='background-images'>

        <swiper-container #backgroundSwiper [config]='swiperBackgroundConfig' appSwiper init='false'>
            <swiper-slide>
                <div class='image'><img alt="Loop Plus Background 1" src='./assets/home/background-1.jpg'/></div>
            </swiper-slide>
            <swiper-slide>
                <div class='image'><img alt="Loop Plus Background 2" src='./assets/home/background-2.jpg'/></div>
            </swiper-slide>
            <swiper-slide>
                <div class='image'><img alt="Loop Plus Background 3" src='./assets/home/background-3.jpg'/></div>
            </swiper-slide>
        </swiper-container>

    </div>

</div><!--/#home-page-wrapper -->
