import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, ErrorInterceptor } from './interceptors';
import { AuthService } from './services';
import { LoadingInterceptor, RequestInterceptor } from './interceptors';
import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { CoreComponent } from './core.component';

@NgModule({ declarations: [CoreComponent], imports: [CommonModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true,
        },
        AuthService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {}
